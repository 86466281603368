.footer {background: var(--color-bg-section); border-top: 1px solid #ddd;}
.footer_inner {position:relative;}
.footer_top {border-bottom:1px solid var(--color-divider); padding-left:9px; padding-right:9px;}
.footer_menu {font-size:0; display: flex; flex-direction: row; flex-wrap: wrap; padding:20px 0 10px;}
.footer_menu li {position:relative; padding: 0 10px; margin-bottom: 10px;}
.footer_menu li.menu_divider {width:1px; position: relative;}
.footer_menu li.menu_divider:before { height: 7px; width:1px; background: var(--color-divider); content: ''; display: block; position:absolute; left:0; top:50%; transform: translateY(-50%);}
/* .footer_menu li:first-child {padding-left:0;}
.footer_menu li:last-child {padding-right:0;} */
.footer_menu li:last-child:before {display:none;}
.footer_menu li a {font-size:11px; line-height: 22px; letter-spacing:var(--letter-spacing);color: #666; display: block;}
.footer_menu li.menu_location a,
.footer_menu li.menu_privacy a {font-weight:700;}
.footer_menu li.menu_divider{ padding: 0;}
.footer_bottom {padding-top:20px; padding-bottom: 30px;position: relative;}
.footer_logo {width:56px; height:23px; display:inline-block; vertical-align: middle;}
.footer_business_info_controler {float:none;position: relative;top: auto;right: auto;border:none;background:none;color: #bbb;padding: 0;font-size:12px;letter-spacing:var(--letter-spacing);cursor: pointer;}
.footer_business_info_controler > span {padding-right: 15px;position:relative; font-size: 0; display: inline-block; vertical-align: middle;}
.footer_business_info_controler >  span:after {display: block;font-family: 'jt-font';content: '\e908';font-size: 13px;font-weight: normal;color: #bbb;position:absolute;right: -2px;top: 50%;margin-top:-6px;-webkit-transition: 300ms;transition: 300ms;}
.footer_business_info_controler.open > span:after {-webkit-transform: rotate(-180deg); -ms-transform: rotate(-180deg); transform: rotate(-180deg);}
.footer_business_info {padding: 13px 0 0; font-size:12px;color: #bbb;}
.footer_business_info li {margin-bottom:3px;}
.footer_business_info li:last-child {margin-bottom:0;}
.footer_business_info li span,
.footer_bottom .footer_business_info li a {color: #999;}
.footer_sns { margin:20px 0; font-size: 0; }
.footer_sns li { display: inline-block; margin-right:9px; }
.footer_sns li a { display: block; width:35px; height:35px; overflow: hidden; text-indent: -9999px; position: relative; border-radius: 50%; background-color:#E2E2E2; }
.footer_sns li a svg { position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); }
/* .footer_sns li a:before { content:""; display: block; position: absolute; top:0; left:0; width:100%; height:100%; border-radius: 50%; background-color:#E2E2E2; background-repeat: no-repeat; background-position: center center; } */
/* .footer_sns li.sns_instagram a:before { background-image: url(/images/components/icon/footer-instagram.svg); }
.footer_sns li.sns_twitter a:before { background-image: url(/images/components/icon/footer-twitter.svg); }
.footer_sns li.sns_facebook a:before { background-image: url(/images/components/icon/footer-facebook.svg); }
.footer_sns li.sns_youtube a:before { background-image: url(/images/components/icon/footer-youtube.svg); }
/* .footer_sns li.sns_linkedin a:before { background-image: url(/images/components/icon/footer-linkedin.svg?v=1.1); } */
.footer_message {font-size:10px;letter-spacing: var(--letter-spacing);color: #bbb;margin-top: 20px;}
.footer_bottom .copyright {margin-top: 19px;font-size:12px;color: #bbb;}
:global(.ios) .footer_menu {padding-top:21px;}
:global(.android) .footer_menu {padding-top:21px;}

@media (min-width: 374px){

    .footer_menu li a { font-size: 12px;}  

}

@media (min-width: 1023px){

    .footer_top { padding-left:14px; padding-right:14px; }
    .footer_menu li a {font-size:13px;}
    :global(.ios) .footer_menu li a {padding-top:20px;}

}