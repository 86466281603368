.container {background: var(--color-primary);font-size:17px;font-weight:700;color: #fff; width: 100%;height: 70px;line-height: 70px;display: block;text-align: center; border:none;padding:0;cursor:pointer;}
.container[disabled] { pointer-events: none; cursor: default; background: #ddd; border-color: #ddd; color: #aaa;}

.kakao{background: var(--color-kakao-y);color: var(--color-kakao-dark);}
.kakao .inner{position:relative; padding-left: 43px;}
.kakao .inner:before{content: ''; position: absolute;top:50%; left: 0; transform: translateY(-50%); width: 34px; height: 26px; background: url(./images/kakao-channel.svg) center center no-repeat;}

.stick_bottom{position: absolute; bottom: 0px; z-index: 2;}

@media (min-width: 680px){
    .container {max-width:500px;}
}