/* 샵 후기 */
.shop_review_header {position:relative;border-bottom: 1px solid #ddd;}
.shop_review_header_inner {padding: 20px 16px 29px;}
html.ios .shop_review_header_inner { padding-top: 22px;}
html.android .shop_review_header_inner { padding-top: 23px;}

.btn_review_write {margin-bottom:34px;}
.btn_review_write span {padding-right: 21px;position:relative;display:inline-block;}
.btn_review_write span:after {font-family: 'jt-font';content: '\e921'; display:block; font-size: 13px;font-weight:normal;color:#fff;position: absolute;right: 0;top: 1px;}
html.ios .btn_review_write span:after {top:0px;}
html.android .btn_review_write {padding-top:0.5px;}
html.android .btn_review_write span:after {top:0px;}

.notice_by_owner_header { position: relative; margin-bottom:8px;}
.notice_by_owner_header .notice_title {padding-left: 22px;font-size:15px;letter-spacing: var(--letter-spacing);font-weight: 700;color:#222;position:relative;}
.notice_by_owner_header .notice_title:before {width:16px;height:14px; background:url(./images/icon-notice.svg) no-repeat;content:'';display:block;position: absolute;left: 0;top: 50%; margin-top:-6px;}
.notice_by_owner_header .notice_date {font-size:13px; font-weight:400; color:#aaa;position: absolute;right: 0;top: 2px;}
html.ios .notice_by_owner_header .notice_title:before {margin-top:-6.5px;}
html.android .notice_by_owner_header .notice_title:before {margin-top:-7px;}

.notice_by_owner p {font-size:15px;line-height: 1.6;color:#666;letter-spacing: var(--letter-spacing);}
.notice_by_owner p + p {margin-top:10px;}

.shop_review_list_inner_wrap {margin-top: 15px;padding-top: 20px;}

.shop_review_list_wrap .review_user_info {padding:20px 16px 19px;background:#f8f8f8;text-align: center;}
.shop_review_list_wrap .review_user_info b {font-size:15px;font-weight:700;color:#222;letter-spacing: var(--letter-spacing);display:block;}
.shop_review_list_wrap .review_user_info span {padding:1px 0 0;font-size:12px; color:#666; letter-spacing: var(--letter-spacing); display: block;}

.shop_review_num { font-size:20px; font-weight:700; letter-spacing: var(--letter-spacing); color:#222;}
.shop_review_option {margin-top: 19px;padding-bottom: 12px;border-bottom:1px solid #ddd;overflow: hidden;}
.shop_review_option > div,
.shop_review_option > div > div { display:inline-block; vertical-align: middle;}
.shop_review_option_photo_only > div > div { margin: 0;}
.shop_review_option .jt_icheck_label span {font-size:14px; color:#666; letter-spacing: var(--letter-spacing);}
.shop_review_option ul {float:right;}
.shop_review_option li {padding-right: 10px;display:inline-block;line-height: 1.7;vertical-align: middle;position:relative;}
/* .shop_review_option li:before {width:1px;height: 8px;background:#ddd;content:'';display:block;position:absolute;right:0;top: 8px;} */
.shop_review_option li:last-child {padding-right:0; margin-right:0;}
.shop_review_option li:last-child:before {display:none;}
.shop_review_option a {font-size:14px;color:#666;letter-spacing: var(--letter-spacing);display: block;}
.shop_review_option li.active a { color:#222; font-weight:700;}

.shop_review_item { display: flex; margin: 16px 0;padding-bottom: 17px;margin-bottom: 16px;border-bottom:1px solid #eee;background-position: left 5px;background-size:38px 38px;background-repeat: no-repeat; position:relative;}
.shop_review_item:last-child {margin-bottom:40px; border-bottom:none;}
.shop_review_item_avatar { width: 48px;  padding-top: 5px;}
.shop_review_item_content { width: calc(100% - 48px);}
.shop_review_item_content:only-child { width: 100%;}
html.ios .shop_review_item_content div[class^=Status_container] {top:0px;}

.shop_review_item .user_name_wrap {position:relative; display:inline-block;}
.shop_review_item .user_name_inner {position: relative; }
.shop_review_item .user_name {font-size:14px; font-weight:700; color:#222; letter-spacing: var(--letter-spacing); display: block;}
.shop_review_item .user_name > span {position: relative;padding-right: 17px;}
.shop_review_item .user_name > span:after {font-family: 'jt-font';content: '\e90a';font-size: 16px;font-weight:normal;position: absolute;right: 0;top: 50%; margin-top:-7px; color: #222;webkit-transition: all 0.2s ease;transition: all 0.2s ease;}
html.ios .shop_review_item .user_name span:after {margin-top:-8px;}
html.android .shop_review_item .user_name span:after {margin-top:-8px;}

.shop_review_item .user_name_tooltip { position: absolute; transform: translateY(-100%); top: 0; left: 100%; margin-left: -9px; margin-top: -7px;}
/*
.shop_review_item #review_tooltip {max-width: 158px;width: 158px;padding: 7px 9px 8px;background:#222;border-radius: 8px;position:absolute;right: -137px;bottom: 25px;z-index: 100;}
.shop_review_item #review_tooltip:before {border-top: 7px solid transparent;border-left: 7px solid #222;border-bottom: 7px solid transparent;border-radius: 8px;content:'';display:block;position:absolute;bottom: -5px;left:0;}
.shop_review_item #review_tooltip span {font-size:11px;color:#fff;letter-spacing: var(--letter-spacing);line-height: 1.4;display: block;}
.shop_review_item #review_tooltip .btn_tooltip_close {position:absolute;right:2px;top: 4px;border:none;background:none;cursor: pointer;}
.shop_review_item #review_tooltip .btn_tooltip_close:before {font-family: 'jt-font';content: '\e91d';font-size: 13px;font-weight:normal;color: #fff;}
*/
.shop_review_item .review_desc {margin-top: 10px; margin-bottom: 10px; color:#666; word-break: break-all; font-size: 15px; line-height: 1.6;}
.shop_review_item .review_util_wrap {margin: 3px 0 0;font-size:0;position: relative;}
.shop_review_item .star_score { display:inline-block;vertical-align: middle;}
.shop_review_item .star_score i {width:12px;height: 17px;font-style:normal;margin-right: 2px;display:inline-block;vertical-align: middle;position:relative;}
.shop_review_item .star_score i:last-child {margin-right:0}
.shop_review_item .star_score i:before {font-family: 'jt-font';content: '\e918';font-size: 12px;font-weight:normal;line-height: 1;position: absolute;left: 0;top: 2px;color: #ddd;}
.shop_review_item .star_score i.active:before {color:#ffc200;}
.shop_review_item .review_time {line-height: 17px; font-size:12px;color:#999;letter-spacing: var(--letter-spacing);display: inline-block;vertical-align: middle;margin-left: 6px;}
.shop_review_item .review_util {font-size:12px;color:#999;letter-spacing: var(--letter-spacing);display:inline-block;vertical-align: middle;margin-left: 7px;}
.shop_review_item .review_util li {padding-right: 7px;margin-right: 4px;display:inline-block;vertical-align: middle;position:relative;}
.shop_review_item .review_util li:after {width:1px;height:6px;background:#ddd;content:'';display:block;position:absolute;right:0;top: 6px;}
.shop_review_item .review_util li:last-child {padding-right:0;margin-right:0;}
.shop_review_item .review_util li:last-child:after {display:none}
.shop_review_item .review_help {position: absolute;right: 0;bottom: 2px;border:none;background:none; cursor: pointer;}
.shop_review_item .review_help span {font-size:10px;color:#bbb;text-align: center;position:relative;display: block;}
.shop_review_item .review_help span:before {font-family: 'jt-font';content: '\e920';font-size: 22px;font-weight:normal;display:block;margin-bottom: 3px;}
.shop_review_item .review_help.active span {color: var(--color-primary);}
.shop_review_item .review_photo {width: 100%;margin-top: 14px;border-radius:8px;overflow:hidden; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden;}
.shop_review_item .review_photo figure {padding-top: 50%;background: #f5f5f5; position: relative;}
.shop_review_item .review_photo figure,
.shop_review_item .review_photo figure img{border-radius:8px; }
.shop_review_item .review_photo + .review_desc {margin-top: 14px;}
.review_actions { margin-left: -8px;}
.review_actions:after{display: table; content: ""; clear:both}
.review_actions button, 
.review_actions a { margin: 0; position: relative; font-size: 12px; padding: 5px 8px; color: var(--color-primary); letter-spacing: var(--letter-spacing); border: none; display: block; background: none; cursor: pointer;}
.review_actions a:before { content: ""; width: 1px; height: 7px; background: #ddd; position: absolute; right: -1px; top: 50%; transform: translateY(-50%);}
.review_actions li { display: block;  position: relative; float: left; }
.review_checking { padding: 4px 7px 4px 6px; background: var(--color-primary); font-size: 11px; line-height: 1; color: #fff; font-weight: 700; letter-spacing: var(--letter-spacing); position: absolute; right: 0; top: 5px; border-radius: 30px;}

.shop_review_no_data{ height: 340px;}

.shop_name { font-weight: 700; color: #222; display: block;}
.shop_name h2 { position: relative; padding-right: 17px; font-size: 18px; display: inline-block; text-overflow: ellipsis; white-space: nowrap; word-wrap: normal; max-width: 70%; overflow: hidden;}
.shop_name h2:after { font-family: 'jt-font'; content: '\e90a'; font-size: 15px; font-weight: normal; position: absolute; right: 0; top: 50%; transform: translateY(-50%); margin-top:1px; color: #222; webkit-transition: all 0.2s ease; -webkit-transition: all 0.2s ease; transition: all 0.2s ease;}
.shop_name.shop_name_full h2 {max-width:100%;} 
html.ios .shop_name h2:after {margin-top: -1px;}
html.android .shop_name h2:after {margin-top: 0px;}

/*
html.ios .shop_review_item #review_tooltip .btn_tooltip_close {top:5px; right:-2px;}
html.android .shop_review_item .user_name span:after {margin-top:-8px}
html.android .shop_review_item #review_tooltip .btn_tooltip_close {top:7px; right:0;}
html.kakao .shop_review_item #review_tooltip .btn_tooltip_close {right:0;}
html.ratio1_25 .shop_review_item .user_name span:after {margin-top:-8px}
*/

.shop_review_list_wrap .review_no_data_wrap {position:static; transform:none; margin:80px auto;}
.shop_review_list_wrap .review_no_data_wrap {height:auto;}
.shop_review_list_wrap .review_no_data .character_img {width:159px; height:126px;}
.shop_review_list_wrap .no_data_wrap .review_no_data b {font-size:14px; font-weight:400;}
.shop_review_list_wrap > div > span {padding:20px 0 19px; font-size:14px; letter-spacing: var(--letter-spacing);}
.shop_review_list_wrap > div > div[class^=HeaderList_tooltip] {margin-top:-2px;}
html.android .shop_review_list_wrap > div > div[class^=HeaderList_tooltip] {margin-top:-1.5px;}

.no_photo_wrap  {position:static; transform:none; margin:80px auto; display:table; text-align:center;}
.no_photo_data {text-align:center;width: 100%;display: table-cell;vertical-align: middle;}
.no_photo_data .character_img {width:auto; height:126px;margin: 0 auto 17px;}
.no_photo_data .character_img p {height: 100%; margin-left:-21px; /*background:url(../images/sub/mypages/character-no-photo.jpg) no-repeat center top;*/ background-size:auto 100%;}
.no_photo_data b {font-size:14px; font-weight:400;}

.photo_popup_wrap {display:none;width: 100%;height:100%;position: fixed;top: 0; background: #222;z-index: 9999;}
.photo_popup_header {text-align:center;line-height:62px; background:transparent; box-shadow: none;}
.photo_popup_close span:after {color: #fff;}
.photo_popup_container {display: block; width:100%; height:100%; position: relative;}
.photo_popup_container img { margin-bottom:10px; width:100%; max-width:500px;}
.photo_popup_container img:last-child {margin-bottom:0;}
.photo_popup_inner {display:block; position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 100%;max-width: 500px;height: auto;z-index: 10;}
.preview_photo_thumb {cursor:pointer;}
.preview_photo_thumb img {position: absolute; top: 0; left: 0; height: 100%; width: 100%; object-fit: cover; object-position: center;}
.photo_popup_overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #222; z-index: 2; }

/* Form */
.review_write_modal{background-color: var(--color-bg);}
.review_write_desc {margin-bottom: 35px;margin-top: 6px; color:#aaa;}
.review_container_inner { padding-top: 23px; padding-bottom:50px; background-color: var(--color-bg);}
.review_write_wrap h2 { margin-bottom: 14px; text-align: center; font-size: 22px; font-weight: 700; letter-spacing: var(--letter-spacing); word-break: break-all; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.review_write_wrap h2 + div { margin-bottom:-4px;}

@media (min-width: 1023px){
    .review_management_wrap button, 
    .review_management_wrap a { font-size: 14px; }
    /*
    .shop_review_header_inner,
    .shop_review_list_wrap {padding: 24px }
    */
    .shop_review_list_wrap > div > span {padding-top:19px; padding-bottom:20px;}
    .shop_review_list_wrap > div > div[class^=HeaderList_tooltip] {margin-top:-1px;}
    html.safari .shop_review_list_wrap > div > div[class^=HeaderList_tooltip] {margin-top:-3px;}

    .shop_review_header_inner {padding-left:24px; padding-right:24px;}
    .shop_review_item_content div[class^=Status_container] {top:-1px;}
    .shop_review_option_photo_only label > span {padding-bottom:2px;}

    .shop_review_item{ margin: 21px 0;}
    .review_actions a:before {top:calc(50% + 1px)}

    html.ios .shop_review_item .user_name span:after {margin-top:-7px;}
}