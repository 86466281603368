.inner {position:relative;margin:0; background: #f8f8f8;border-radius: 12px;}
.input_wrap {background:#f8f8f8; border-radius:8px;}
.input {width:calc(100% - 90px);height: 50px;border:none;background:#f8f8f8; padding: 17px 0px 17px 16px; border-radius:8px;line-height:1;font-size:15px;}
.submit {height:50px;background: #eee;border: none;padding:0;position: relative;position: absolute;right: 0;top: 0;margin:0;padding: 0 15px;border-radius: 0 8px 8px 0;cursor:pointer;}
.submit:after {display: block;width: 100%;height: 100%;font-family: 'jt-font';content: '\e913';font-size: 19px;font-weight: normal;text-align: center;line-height: 50px;color: #222; transition: 300ms;}
.remove {right: 60px; top: 13px; width: 14px; height: 14px; border: none; padding: 12px; background: url(./images/icon-delete.svg) no-repeat center; background-size: 18px 18px; cursor: pointer; position: absolute; }
.seamless .inner { background: #fff;}
.seamless .input_wrap,
.seamless .input, 
.seamless .submit { background: #fff;}
.seamless .remove{right: 55px;}
:global(.ios) .seamless .remove {top:12px;}
:global(.ios) .seamless .submit {top:-1px;}

.inner input[type="search"]::-webkit-search-decoration,
.inner input[type="search"]::-webkit-search-cancel-button,
.inner input[type="search"]::-webkit-search-results-button,
.inner input[type="search"]::-webkit-search-results-decoration { -webkit-appearance:none;}

@media(min-width:1023px){
    .seamless .input {padding-left:24px;} 
    .seamless .submit {padding-right:24px;}
}