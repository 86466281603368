.blog_banner {width:100%;height:110px;padding: 16px 16px; background: var(--color-primary) url('./images/character-blog.png') no-repeat 83% center;}
.blog_banner p { font-size:20px; font-weight:700; line-height:1.4; color:#fff; letter-spacing: var(--letter-spacing); }
.blog_banner p > i {width:24px; height:24px;margin-top:2px; background:url(./images/icon-earth.svg) no-repeat; background-size:100% 100%;font-style:normal;display:inline-block;vertical-align:top;}
html.ie .blog_banner p > i {width:25px;}
.blog_banner span {margin-top: 4px;font-size: 14px;line-height:1.5;color:#fff;letter-spacing: var(--letter-spacing);display:block;}
.blog_list_wrap {padding-top:5px; padding-bottom: 37px;}
.blog_item:first-child figure {margin-top:0;}
.blog_item > a { display:block;border-bottom: 1px solid #eee; padding-bottom: 27px;padding-top: 25px;}
.blog_item:last-child > a {margin-bottom:0; border-bottom:none;}
.blog_item figure {margin-top: 6px;padding-top: 61%;border-radius: 7px;position: relative;}
.blog_item figure img{ position: absolute; top: 0; left: 0;border-radius: 7px;}
.blog_item figure + h2 {margin-top: 17px; }
.blog_item h2 p {font-weight:700; font-size:18px;letter-spacing: var(--letter-spacing);color:#222;display: inline-block;position: relative; overflow:hidden;}
.blog_item h2 p span {position:relative;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-width: 100%;max-height: 3.2em; line-height: 1.6; word-break: break-all; overflow: hidden; text-overflow: ellipsis; white-space: normal; -webkit-line-clamp: 2; -webkit-box-orient: vertical; display: -webkit-box;}
.blog_item.new_item .icon_new {width:9px;height:10px; background:url(./images/new-label.svg) no-repeat; display: block;position:absolute;left:1px;top:9px;}
.blog_item.new_item h2 p { text-indent: 16px;}
.blog_item .blog_desc {margin-top: 2px;font-size:15px;color:#666;letter-spacing: var(--letter-spacing);display:-webkit-box;width:100%;max-height:46px;line-height: 1.7;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.blog_item .blog_desc + time {margin-top:18px;}
.blog_item time {font-size:13px;font-weight:400;color:#aaa;display: block;}
html.ios .blog_item.new_item .icon_new {top:8px;}
html.android .blog_item h2 p span {padding-right:1px;}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx){
    .blog_banner {
        background-image: url(./images/character-blog@2x.png);
        background-size: 135px 132px;
    }
}

@media(min-width:1023px){
    .blog_banner {height: 125px; padding:21px 24px 27px;}
}