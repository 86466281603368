
/* */
.inquiry_content{padding-top:53px}

.sub_inquiry_article .sub_tab_nav_wrap + div, .inquiry_popup_open .sub_tab_nav_wrap + div {margin-top: 0;}
.sub_inquiry_article .tabs_panels > div{padding-top: 53px;}
.page-template-sub-inquiry-view .sub_inquiry_article .tabs_panels > div {padding-top: 53px;}
.sub_inquiry_article .tabs_panels > div.jt_inquiry_form_inner {padding-bottom: 40px;}
.sub_inquiry_article .sub_tab_nav_wrap {top:0;}
.sub_inquiry_article .sub_tab_nav_wrap.loaded_fix {top:62px;}
.inquiry_view_popup {position:fixed}
.inquiry_view_popup .tabs_panels > div {padding-top: 115px;}
.inquiry_tab .tabs_menu_item {width:50%;}
.jt_inquiry_form_inner {padding-top: 22px; padding-bottom: 50px;}
.jt_inquiry_form_inner > div:first-child { margin-top: 0;}
.jt_inquiry_form_inner .inquiry_email_form .remove_value {width:14px;height:14px;border:none;padding:12px;background:red; background-size:15px 15px;cursor:pointer;position:absolute;right:0;top:5px; display:none;}
.jt_inquiry_form_inner .inquiry_email_form.active .remove_value {display:block;}
.jt_inquiry_form_inner .jt_pagination_mob {margin-left:0; margin-right:0;}
#to_inquiry .inquiry_content_container {margin-bottom: 26px;}
#to_inquiry .inquiry_content_container:last-child {margin-bottom:24px;}
#to_inquiry .jt_form_data_files {position: relative;}
#to_inquiry .preview_file_thumb {position: absolute;top:0; left:0;margin:0;}
.inquiry_content_container.disabled textarea:disabled {opacity: 1;}
.inquiry_content_submit{ margin-top: 30px;}
html.android .jt_inquiry_form_inner > div > label > span > span {top:0px}
html.android .jt_inquiry_form_inner > div > button[class*=Form_agreement_privacy_btn] {margin-top:2px;}

#to_inquiry .jt_icheck_label span {letter-spacing: -0.04em;padding-left: 9px;font-size: 14px;}
#to_inquiry .jt_form_submit {margin-top:40px;}
#to_inquiry .jt_form_submit .jt_btn_basic {border:0;}

.inquiry_privacy_list {width: 84px; vertical-align: top; display: inline-block; font-size: 0;}
/*
.inquiry_type .selectric .label {color:#bbb;}
.inquiry_type .selectric .label.active {color:#666;}
*/
/*.inquiry_content .jt_form_submit button.submit_loading {font-size:0;background: var(--color-primary) url(../images/icon/spinner-white@2x.gif) no-repeat center; background-size:25px 25px;}*/

/*
html.mobile.inquiry_popup_open {overflow:hidden;}
html.ios .inquiry_privacy_btn span:after {margin-top:-10px;}
html.android .inquiry_privacy_btn {padding-top:4px;}
html.android .inquiry_privacy_btn span:after {margin-top:-10px;}
*/


/* 나의 문의내역 - LIST */
.my_inquiry_list_container { margin-top:8px; margin-bottom: 50px;}
.my_inquiry_list_container li{font-size:0;border-bottom: 1px solid #ddd;}
.my_inquiry_list_container li a {padding-bottom: 16px;padding-top: 14px;display:block; position:relative;}
.my_inquiry_title_day {width:-webkit-calc(100% - 66px);width:calc(100% - 66px);display: inline-block;vertical-align: middle;}
.my_inquiry_title_day b { word-break: keep-all;overflow: hidden;text-overflow: ellipsis;white-space: normal;-webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;}
.my_inquiry_title_day span {font-size:13px;color:#aaa;margin-top:3px;display: block; font-weight: 400;}
.my_inquiry_state {width:66px;text-align:right;display: inline-block;vertical-align: top;margin-top: 3px;}
.my_inquiry_state span {display: inline-block; padding: 0px 9px 1px;border-radius: 15px;background-color: #bbb;font-size:12px;font-weight: 400; line-height:1.5; color:#fff;}
.my_inquiry_state.complete span {background-color: var(--color-primary);}
.my_inquiry_list_container div[class^=Status_container] {top:17px;}
html.ios .my_inquiry_state span {padding-top:1px}
html.ios .my_inquiry_list_container li a {padding-top:13.5px;}
html.android .my_inquiry_state span {padding-top:2px;}

.inquiry_no_data_wrap {display: table; height:100%; text-align: center; width: 100%;}
.inquiry_no_data {text-align:center;width: 100%;display: table-cell;vertical-align: middle;}
.inquiry_no_data .character_img {width: 202px;height: 157px;margin: 0 auto 27px;}
.inquiry_no_data .character_img p {height: 100%;margin-left: -19px;font-size:14px;color:#666;text-align:center;letter-spacing:var(--letter-spacing);/*background: url(../images/sub/mypages/character-inquiry-no-data-v2.jpg) no-repeat center top;*/ background-size:auto 100%;}


/* 나의 문의내역 - 상세 */
#my_inquiry { height:calc(100vh - 61px) }
#my_inquiry.has_reply {background:#f8f8f8;}
.my_inquiry_single {padding-bottom: 40px;padding-top: 21px;background:#fff;}
.my_inquiry_single_title {font-size:0;padding-bottom: 16px;border-bottom: 1px solid #ddd;}
.my_inquiry_single_title .my_inquiry_title_day b { font-size:18px; font-weight:700; -webkit-line-clamp: inherit; -webkit-box-orient: inherit; text-overflow: inherit; overflow:visible; display:block;}
.my_inquiry_single_content {margin-top: 15px;}
.my_inquiry_single_content li {font-size:0;padding-bottom: 7px;}
.my_inquiry_single_content li:last-child {padding-bottom:0;}
.my_inquiry_single_content_list_title {width: 85px;vertical-align: top;display: inline-block;font-size: 0; padding-top:1px;}
.my_inquiry_single_content_list_title b {font-size: 13px;font-weight:700;color:#222;letter-spacing: var(--letter-spacing);line-height: 1.7;}
.my_inquiry_single_content_list_post {width: calc(100% - 85px);padding-top: 2px;display: inline-block;vertical-align: top;letter-spacing: var(--letter-spacing);}
.my_inquiry_single_content_list_post p {font-size: 14px;color:#666;letter-spacing: var(--letter-spacing);line-height: 22px;word-break: break-all;}
.my_inquiry_single_content_list_post .inquiry_photo img {top: 50%; transform: translateY(-50%);}
.my_inquiry_single .my_inquiry_title_day span {margin-top: 3px}
.my_inquiry_file_img {border-radius: 8px;overflow: hidden;margin-top: 14px;padding-top:50.21%;}
.my_inquiry_file_img img {-o-object-fit: cover;object-fit: cover;}

.my_inquiry_reply {padding: 24px 0 0;}
.my_inquiry_reply figure {width: 22px;margin-left: 1px;display: inline-block;vertical-align: middle;}
.my_inquiry_reply b {font-size: 14px;font-weight:700;color: var(--color-primary);letter-spacing: var(--letter-spacing);vertical-align: middle;padding-left: 5px;}
.my_inquiry_reply_post {padding-top: 14px; padding-bottom: 34px;}
.my_inquiry_reply p {font-size: 14px;line-height: 22px; color:#666;letter-spacing: var(--letter-spacing);padding-top: 12px;word-break: break-all;}
.my_inquiry_reply p:first-child {padding-top:0;}
.my_inquiry_single .my_inquiry_state{margin-top:2px; /* margin-bottom:10px; */}
html.android .my_inquiry_single_content_list_title b {line-height:1.8;}

/* .inquiry_view_popup .photo_popup_wrap { width: 100% !important; left: 0;}
.inquiry_view_popup { background:transparent; width:100% !important; left:0;}
.inquiry_view_popup_inner { max-width:500px; margin:0 auto; background:#fff;} */
.inquiry_photo_popup {display:none;width: 100%;height:100%;position: fixed;top: 0; background: #222;z-index: 9999;}
.inquiry_photo_header {text-align:center;line-height:62px; background:transparent; -webkit-box-shadow: none; box-shadow: none;}
.inquiry_photo_close span:after {color: #fff;}
.inquiry_photo_container {display:table; width:100%; height:100%;}
.inquiry_photo_container img { margin-bottom:10px; width:100%;}
.inquiry_photo_container img:last-child {margin-bottom:0;}
.inquiry_photo_inner {display:table-cell; vertical-align:middle;}

.btn_goto_inquiry_list  { width: calc(100% - 32px); height: 46px; line-height: 46px; margin:40px 16px 0; background: #fff; color:#aaa; font-weight:400; border:1px solid #e2e2e2; position: relative; bottom: 40px;}



@media (min-width: 1023px){
    .my_inquiry_list_container li a { padding-top:17px; padding-bottom:20px;}
    .my_inquiry_list_container div[class^=Status_container] {top:20px;}
    html.ios .inner {padding-top:0px}
}